import { z } from 'zod';
import { StorageEnum } from '@/services/FileService/enums';
import isValidJsonString from '@/shared/helpers/isValidJsonString';
import { FirebaseOptions } from 'firebase/app';
import { appEnvironments } from '@/constants/app-environments';

export const envSchema = z.object({
  DOMAIN: z.enum(appEnvironments),
  API_URL: z.string().url().default('http://localhost:3000/api/v1'),
  BASE_URL: z.string().url().default('http://localhost:3001'),
  FIREBASE_CONFIG: z
    .string()
    .refine(isValidJsonString, {
      message: 'REACT_APP_FIREBASE_CONFIG must be a valid JSON string',
    })
    .transform((value) => z.custom<FirebaseOptions>().parse(JSON.parse(value))),
  FILE_PROVIDER: z.nativeEnum(StorageEnum).default(import.meta.env.DEV ? StorageEnum.Localstack : StorageEnum.AWS),
  TRANS_CLIENT_ID: z.string().min(1).default('4cad0c5a-91a1-4220-9592-e32fc3d87e8f'),
  OUTLOOK_CLIENT_ID: z.string().min(1).default('88dd5e57-09d7-4e1b-b4d5-52c481ccff67'),
});

export function validateEnv() {
  try {
    return envSchema.parse({
      DOMAIN: import.meta.env.REACT_APP_DOMAIN,
      API_URL: import.meta.env.REACT_APP_API_URL,
      BASE_URL: import.meta.env.REACT_APP_BASE_URL,
      FIREBASE_CONFIG: import.meta.env.REACT_APP_FIREBASE_CONFIG,
      FILE_PROVIDER: import.meta.env.REACT_APP_FILE_PROVIDER,
      TRANS_CLIENT_ID: import.meta.env.REACT_APP_TRANS_CLIENT_ID,
      OUTLOOK_CLIENT_ID: import.meta.env.REACT_APP_OUTLOOK_CLIENT_ID,
    });
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error('\n❌ Invalid environment variables:', error.format());
      throw new Error('Invalid environment variables');
    }
    throw error;
  }
}

export const env = validateEnv();
