import { CircularProgress } from '@mui/material';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import './index.css';
import { Composer, Module } from '@frontend-composer/core';
import config from './config';
import { useEffect } from 'react';
import { env } from '@/env';

export const sentryAppEnvironment =
  (env.DOMAIN === 'prod' && 'production') ||
  (env.DOMAIN === 'stage' && 'stage') ||
  (env.DOMAIN === 'local' && 'local') ||
  'development';

Sentry.init({
  dsn: 'https://84d188516e04875c59b73c81386f89f0@o4508172706316288.ingest.de.sentry.io/4508184216338512',
  release: import.meta.env.REACT_APP_VERSION,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      mask: ['input[type="password"]'],
    }),
    Sentry.feedbackIntegration({
      autoInject: false,
      colorScheme: 'light',
      showBranding: false,
    }),
  ],

  environment: sentryAppEnvironment,

  tracesSampleRate: 1.0,

  tracePropagationTargets: ['localhost', /^https:\/\/cargonest\.ai/],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  beforeSend(event) {
    if (event.request && event.request.url) {
      const url = new URL(event.request.url);
      if (['/', '/login'].includes(url.pathname)) {
        return null;
      }
    }
    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const components = {
  layoutLoader: CircularProgress,
  moduleLoader: CircularProgress,
};

root.render(
  <Composer config={config} components={components}>
    {(content) => <Module name="rootModule">{content}</Module>}
  </Composer>,
);
